import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "api-reference",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#api-reference",
        "aria-label": "api reference permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`API Reference`}</h1>
    <h6 {...{
      "id": "setup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h6" {...{
        "href": "#setup",
        "aria-label": "setup permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setup`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/sdk-reference/setup#sdksetconfigconfig"
        }}>{`sdk.setConfig(config)`}</a></li>
    </ul>
    <h6 {...{
      "id": "views",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h6" {...{
        "href": "#views",
        "aria-label": "views permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Views`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/sdk-reference/views#sdkrendercreatetransactionpayload-options"
        }}>{`sdk.renderCreateTransaction(payload, options)`}</a></li>
    </ul>
    <h6 {...{
      "id": "constants",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h6" {...{
        "href": "#constants",
        "aria-label": "constants permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Constants`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": ""
        }}>{`sdk.ENVS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": ""
        }}>{`sdk.FUNDERS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": ""
        }}>{`sdk.MEDICARE.REFERRER_TYPE`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": ""
        }}>{`sdk.MEDICARE.REFERRAL_PERIODS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": ""
        }}>{`sdk.HEALTH_FUNDS`}</a></li>
    </ul>
    <h6 {...{
      "id": "members",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h6" {...{
        "href": "#members",
        "aria-label": "members permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Members`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/sdk-reference/members#sdkmembersdiscovermemberoptions"
        }}>{`sdk.members.discoverMember(options)`}</a></li>
    </ul>
    <h6 {...{
      "id": "claim-items",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h6" {...{
        "href": "#claim-items",
        "aria-label": "claim items permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Claim items`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/sdk-reference/claim-items#sdkclaimitemsgetbusinessclaimitemsoptions"
        }}>{`sdk.claimItems.getBusinessClaimItems(options)`}</a></li>
    </ul>
    <h6 {...{
      "id": "transactions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h6" {...{
        "href": "#transactions",
        "aria-label": "transactions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Transactions`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/sdk-reference/transactions#sdktransactionsgetbusinesstransactionoptions"
        }}>{`sdk.transactions.getBusinessTransaction(options)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/sdk-reference/transactions#sdktransactionsdownloadpdfoptions"
        }}>{`sdk.transactions.downloadPDF(options)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/sdk-reference/transactions#sdktransactionsgetpaymentreportoptions"
        }}>{`sdk.transactions.getPaymentReport(options)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/sdk-reference/transactions#sdktransactionsgetprocessingreportoptions"
        }}>{`sdk.transactions.getProcessingReport(options)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      